
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
 //   xxl: 1400px
    xxl: 1440px
  );

  $container-max-widths: (
  //sm: 540px,
  //md: 720px,
  xs:328px,
  sm: 328px,
  md: 720px,
  lg: 960px,
  xl: 1032px,
//  xxl: 1320px
  xxl: 1032px
);


/* Bootstrap を読み込む */
@import "~bootstrap/scss/bootstrap.scss";